<template>
  <div class="ccsqdTpl">
   

  </div>
</template>

<script>
export default {
  name: "TmcCtripHome",
  data() {
    return {

    };
  },
  mounted() {
    // 获取完整的URL
    let urlQueryObj = this.$common.parseQueryString(window.location.search) || {};
    urlQueryObj.channel = 2; // 1 kg  2 xc
    let origin = window.location.origin;

    let pathname = window.location.pathname;
    // let host = window.location.host;
    // let hash = window.location.hash; // #/yjTravelTmc
    this.$common.localset('tmcLoginInfo',urlQueryObj)
    let url = `${origin}${pathname}#/tmcLogin`
    console.log(url);
    window.location.replace(url)
  },
  methods: {
 
  },
};
</script>

<style scoped>
.ccsqdTpl {
  width: 750px;
  margin: 0 auto;
  font-size: 14px;
  padding: 20px;
}
.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    height: 100%;
    /* display: block; */
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .title{
    font-weight: 600;
  }
</style>
